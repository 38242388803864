<template>
<div style="text-align: center;">
    <div class="row">
        <div class="col-xl-12">
            <img :src="params.value.src" class="table-img " alt="" /><br>
        </div>
    </div>
    <div class="row" style="margin-top: -12px;">
        <div class="col-xl-12">
            <span class="state-round"> <span :style="'background-color:'+ statee(params.value.state ) " class="round"></span>{{ params.value.state }}</span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'CellRendererLink',
    methods: {
        statee(sta) {
            if (sta == 'Active') {
                return "#DAF107";
            } else if (sta == 'Potential') {
                return "#859309";
            } else if (sta == 'Contacted') {
                return "#1466E2";
            } else if (sta == 'Standby') {
                return "#999999";
            }
        },
    },
    computed: {
        url() {
            return '/myitems/edit/' + this.params.data.id

            // Below line will be for actual product
            // Currently it's commented due to demo purpose - Above url is for demo purpose
            // return "/apps/user/user-view/" + this.params.data.id
        }
    }
}
</script>

<style lang="scss" scoped>
.table-img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 5px;
}

.h-10 {
    height: 60px;
}

.state-round {
    font-weight: bold;
    color: #000;
    margin-left: -18px;
    font-size: 10px;
    .round {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        line-height: 5px;
        background-color: #DAF107;
        display: inline-block;
        margin-right: 5px;
    }
}
</style>
