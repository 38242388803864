
<template>
<div style="text-align: center;">
    <div class="row">
        <div class="col-xl-12">
           <span class="title-table-name">{{ params.value.name }} </span>
 
        </div>
    </div>
    <div class="row" style="margin-top: -7px;">
        <div class="col-xl-12">
             <span class="title-sub"> {{ params.value.title }}</span>
        </div>
    </div>
</div>
</template>


<script>
export default {
    name: 'CellRendererLink',
    computed: {
        url() {
            return '/myitems/edit/' + this.params.data.id

            // Below line will be for actual product
            // Currently it's commented due to demo purpose - Above url is for demo purpose
            // return "/apps/user/user-view/" + this.params.data.id
        }
    }
}
</script>

<style lang="scss" scoped>
.table-img {
    height: 40px;
    width: 70px;
    margin-right: 10px;
    border-radius: 5px;
}
.title-table-name{
    font-weight: bold;
    color:#000;
    font-size: 16px;
        margin: 5px 0;
    display: block;
}
.title-sub{
       margin-top: -2rem;
    display: block;
    color: #595959;
}
</style>
