<template>
  <div>
    <div class="row">
      <div class="col-xl-8">
        <h1 style="color:#000;font-weight:bold">All Influencers</h1>
      </div>

      <div class="col-xl-4">
        <Dropdown7></Dropdown7>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-xl-4">
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <b-form-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          ></b-form-input>
        </div>
      </div>
      <div class="col-xl-4">
        <v-form ref="form" lazy-validation>
          <b-form-select
            placeholder="Statue"
            required
            :options="statueItems"
            v-model="statusFilter"
          ></b-form-select>
        </v-form>
      </div>
    </div>

    <div class="row mb-8">
      <div class="col-xl-12">
        <ag-grid-vue
          class="ag-grid-table ag-theme-material"
          :gridOptions="gridOptions"
          style="width: 100%; height: 500px;"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :rowData="items2"
          rowSelection="multiple"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="6"
        >
        </ag-grid-vue>
      </div>
    </div>
    <div class="row" style="float:right">
      <div class="col-lg-12">
        <v-btn color="success" class="mr-4" style="background-color:#F30E53">
          Delete
        </v-btn>

        <v-btn
          color="error"
          class="mr-4"
          style="background-color:#1374D6;padding: 0 2rem;"
        >
          MAKE A LIST
        </v-btn>

        <b-dropdown
          id="dropdown-left"
          text="status"
          variant="primary"
          class="m-2"
          style="background-color:#DBE9F7"
        >
          <b-dropdown-item href="#">Action</b-dropdown-item>
          <b-dropdown-item href="#">Another action</b-dropdown-item>
          <b-dropdown-item href="#">Something else here</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
// import '@/assets/scss/agGridStyleOverride.scss'
import CellRendererAvatars from "@/view/pages/CellRendererAvatar.vue";
import CellRendererName from "@/view/pages/CellRendererName.vue";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    Dropdown7,
    AgGridVue,
    CellRendererAvatars,
    CellRendererName,
  },

  data() {
    return {
      overlayNoRowsTemplate: "No Influencer Found",
      statueItems: [
        {
          text: "All",
          value: "all",
        },
        "Active",
        "Potential",
        "Contacted",
        "Standby",
      ],

      statusFilter: "all",
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: false,
        flex: 1,
        maxWidth: 120,
        filter: false,
      },
      gridOptions: {
        frameworkComponents: {
          CellRendererAvatars: CellRendererAvatars,
          CellRendererName: CellRendererName,
        },
        rowHeight: 70,
        columnDefs: [
           {
            maxWidth: 70,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "",
            field: "img",
            maxWidth: 100,
            // checkboxSelection: true,
            filter: false,
            cellRendererFramework: "CellRendererAvatars",
            filterValueGetter: (params) => {
              return params.data.img.state;
            },
          },
          {
            headerName: "NAME",
            field: "name",
            maxWidth: 100,
            filter: false,
            cellRendererFramework: "CellRendererName",
          },
          {
            headerName: "views",
            field: "views",
            filter: false,
          },
          {
            headerName: "City",
            field: "City",
            filter: false,
          },
          {
            headerName: "PROFIT",
            field: "InstagramRate",
            filter: false,
          },
          {
            headerName: "IG FOLLOWERS",
            field: "Instagramfollowers",
            minWidth: 140,
            filter: false,
          },
          {
            headerName: "IG RATE",
            field: "InstagramRate",
            width: 140,
            filter: false,
          },
          {
            headerName: "SNAP VIEWS",
            field: "SnapViews",
            width: 140,
            filter: false,
          },
          {
            headerName: "Last 7 days",
            field: "Last7days",
            width: 140,
            filter: false,
          },
          {
            headerName: "Yesterdays Snaps",
            field: "YesterdaysSnaps",
            width: 140,
            filter: false,
          },
          {
            headerName: "LastUpdated",
            field: "LastUpdated",
            width: 140,
            filter: false,
          },
        ],
      },
      searchData: [
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Active",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Potential",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Potential",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
      ],
      items2: [
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Active",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Potential",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Potential",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
        {
          img: {
            src: "media/svg/misc/test3.png",
            state: "Contacted",
          },
          name: {
            name: "Khalid Ali",
            title: "@khalid.123",
          },
          views: "2M",
          City: "Dubai",
          Profit: "AED 140K",
          Instagramfollowers: "2M",
          InstagramRate: "2.1",
          SnapViews: "34",
          Last7days: "AED 140K",
          YesterdaysSnaps: "AED 140K",
          LastUpdated: "AED 140K",
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    setColumnFilter(column, val) {
      if (this.gridApi == null) return;
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = {
          type: "equals",
          filter: val,
        };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.typeFilter = this.statusFilter = {
        label: "All",
        value: "all",
      };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      let searchArray = [];
      for (let i = 0; i < this.searchData.length; i++) {
        if (
          this.searchData[i].name.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          searchArray.push(this.searchData[i]);
        }
      }
      this.items2 = searchArray;

      //   this.gridApi = this.gridOptions.api;
      //   this.gridApi.setQuickFilter(val);
    },
  },
  watch: {
    typeFilter(obj) {
      this.setColumnFilter("type", obj.value);
    },
    statusFilter(obj) {
      console.log(obj);
      this.setColumnFilter("img", obj);
    },
    conditionFilter(obj) {
      this.setColumnFilter("condition", obj.value);
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        //  this.gridApi.paginationGoToPage(val - 1)
      },
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    /* =================================================================
          NOTE:
          Header is not aligned properly in RTL version of agGrid table.
          However, we given fix to this issue. If you want more robust solution please contact them at gitHub
        ================================================================= */
    // if (this.$vs.rtl) {
    //     const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
    //     header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.min.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-material.min.css";

.ag-grid-table {
  box-shadow: 0px 0px 8px 2px #f5f5f5;
  border-radius: 20px;
  padding: 1rem;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 12px !important;
  font-weight: bold !important;
}

.ag-theme-material .ag-ltr .ag-selection-checkbox {
  margin-right: 5 !important;
}

.ag-theme-material .ag-ltr .ag-cell {
  font-size: 12px;
}

.ag-header-cell-text {
  font-weight: bold;
  color: #000;
  white-space: initial;
  font-size: 11px;
  text-transform: uppercase;
  word-break: break-word;
}

.ag-header-row-floating-filter {
  display: none;
}

.ag-focus-managed {
  height: 70px !important;
  min-height: 70px !important;
}
.ag-paging-panel {
  justify-content: start !important;
}
.ag-theme-material .ag-paging-row-summary-panel {
  display: none !important;
}
.ag-theme-material .ag-paging-button {
  background-color: #f3fcff;
  padding: 5px;
  border-radius: 5px;
  color: #82ceca;
  font-weight: bold;
}
.ag-paging-description {
  color: #82ceca;
}
</style>
